import Nav from './Nav'
import React, { memo } from 'react'
import Footer from './Footer'

const LayoutHome = ({ children, linear }) => {
    const imgStyle = 'md:w-[500px] w-[200px] absolute rtl:right-2 lrt:left-2'

    return (
        <div className={`flex flex-col ${linear ? '' : 'gap-12'}`}>
            <div className={`${imgStyle} top-2`}>
                <img src="/img/home/ellipse1.png" alt="ellipse1" />
            </div>
            <div className={`${imgStyle} top-[400px] rtl:!left-2 lrt:!right-2`}>
                <img src="/img/home/ellipse2.png" alt="ellipse2" />
            </div>
            {linear ? null : (
                <div className={`${imgStyle} top-[900px]`}>
                    <img src="/img/home/ellipse2.png" alt="ellipse3" />
                </div>
            )}
            <div
                className="flex w-full flex-col z-10"
                style={{
                    background: linear
                        ? 'transparent linear-gradient(180deg, #ddd8d533 20%, #ddd8d580 100%) 0% 0% no-repeat padding-box'
                        : 'none'
                }}
            >
                <Nav />
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default memo(LayoutHome)
