const multiTypes = {
    ID: [
        'id',
        'surveyId',
        'supervisorId',
        'operatorId',
        'surveyTemplateId',
        'faqId',
        'orderId',
        'categoryId',
        'questionId',
        'targetAudienceUserId',
        'researchTeamId',
        'assignmentId',
        'assigmentId',
        'assignmentID',
        'surveyResponseId',
        'operatorEvaluationId',
        'targetAudienceId',
        'configurationId',
        'surveyOrderId',
        'userId',
        'keycloakUserId',
        'customSurveyId',
        'commentId',
        'researcherId',
        'surveyScaleId',
        'dimensionId',
        'dimensionExpressionId',
        'encodingGroupId',
        'dimensionFinalDegreeId',
        'responseId',
        'writeSurveyId',
        'analyticsDashboardId',
        'initResponseId',
        'auditorId'
    ],
    String: [
        'email',
        'startDate',
        'endDate',
        'surveyJson',
        'surveyTheme',
        'orderName',
        'surveyResponseString',
        'newPassword',
        'inviteToken',
        'file',
        'fileType',
        'filePath',
        'fileName',
        'analysisFile',
        'questionJson',
        'token',
        'nameEnglish',
        'nameArabic',
        'newPassword',
        'keyclaokIdString',
        'rejectionNotes',
        'rejectRequestNotes',
        'comment',
        'operatorComment',
        'page',
        'size',
        'createdDate',
        'status',
        'message',
        'surveyName',
        'dimensionName',
        'details',
        'condition',
        'quote',
        'selectedAddons',
        'questionName',
        'dashboardName',
        'submitDate',
        'region',
        'city',
        'researcherName',
        'supervisorName',
        'keyword',
        'bank',
        'iban',
        'rejectionNote',
        'callLaterDate'
    ],
    Float: ['quote', 'boxPlot'],
    Int: ['responses', 'totalTeamMember', 'rate'],
    Addons: ['addons', 'orderStage']
}

const singleTypes = {
    surveyType: 'SurveyType',
    surveyStatus: 'SurveyStatus',
    orderStatus: '[OrderStatus]',
    hasAddons: '[Addons]',
    progressStatus: 'ProgressStatus',
    operatorStatus: 'OperatorStatus',
    faqStatus: 'FaqStatus',
    assignmentType: 'AssignmentType',
    surveyDTO: 'SurveyDTO',
    customSurveyDTO: 'CustomSurveyDTO',
    reviewOptionDTO: 'ReviewOptionDTO',
    auditOptionDTO: 'AuditOptionDTO',
    collection: 'CollectionOptionsDTO',
    addonDTO: 'AddonDTO',
    assignSurveyOrderToSupervisorDTO: 'AssignSurveyOrderToSupervisorDTO',
    assignSurveyOrderToResearcherDTO: 'AssignSurveyOrderToResearcherDTO',
    userAssignmentDTO: 'UserAssignmentDTO',
    completionTime: 'Float',
    location: 'LocationDTO',
    isCompleted: 'Boolean',
    responseStatus: 'ResponseStatus',
    auditStatus: 'AuditStatus',
    responseSource: 'ResponseSource',
    userDTO: 'UserDTO',
    userInfo: 'UserInfoDTO',
    userFavorite: 'UserFavoriteDTO',
    navBarMode: 'Boolean',
    transferOperatorDTO: 'TransferOperatorDTO',
    operatorDTO: 'OperatorDTO',
    operatorEvaluationDTO: 'OperatorEvaluationDTO',
    targetAudienceDTOS: 'TargetAudienceDTO',
    surveyTemplateDTO: 'SurveyTemplateDTO',
    surveyScaleDTO: 'SurveyScaleDTO',
    faqDTO: 'FAQDTO',
    language: 'Languages',
    faqLang: 'Languages',
    configurationValueDTO: 'ConfigurationValueDTO',
    questionsCategoryDTO: 'QuestionsCategoryDTO',
    surveyQuestionDTO: 'SurveyQuestionDTO',
    targetAudienceUserDTO: 'TargetAudienceUserDTO',
    researchTeamDTO: 'ResearchTeamDTO',
    quote: 'OrderQuote',
    assignmentResponses: 'Response',
    rejectAssignmentReason: 'RejectAssignmentReason',
    operatorCommentDTO: 'OperatorCommentDTO',
    orderStatics: 'OrderStatics',
    isTutorialNeeded: 'Boolean',
    checkSolved: 'Boolean',
    isAllQuestionsRequired: 'Boolean',
    helpMessageDTO: 'HelpMessageDTO',
    HelpMessageStatus: 'HelpMessageStatus',
    dimensionDTO: 'DimensionDTO',
    encodingGroupDTO: 'EncodingGroupDTO',
    dimensionFinalDegreeDTO: 'DimensionFinalDegreeDTO',
    collectOptionDTO: 'CollectOptionDTO',
    reportOptionDTO: 'ReportOptionDTO',
    targetAudiences: 'TargetAudienceDTO',
    orderMultiDTO: 'OrderMultiDTO',
    newOrderMessageDTO: 'NewOrderMessageDTO',
    reviewCommentSource: 'ReviewCommentSource',
    analyticsDashboardGraphDTO: 'AnalyticsDashboardGraphDTO',
    graphType: 'GraphType',
    metric: 'Metric',
    statisticType: 'StatisticType',
    accountFileTypes: 'AccountFileTypes',
    callCenterStatus: 'CallCenterStatus',
    responsesId: '[ID]',
    surveyResponsesId: '[ID]',
    evaluationDTO: 'EvaluationDTO'
}

const getTypes = () => {
    for (const key in multiTypes) {
        if (Object.hasOwnProperty.call(multiTypes, key)) {
            multiTypes[key]?.forEach((type) => {
                singleTypes[type] = key
            })
        }
    }
    return singleTypes
}

export default getTypes()
