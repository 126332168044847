import { ACCESS_TOKEN_URI, AUTH_URI, CLIENT_ID, getEnv, LOGOUT_URI, ORIGIN } from '..'
import jwtDecode from 'jwt-decode'

export const authnConfig = {
    clientId: getEnv(CLIENT_ID),
    accessTokenUri: getEnv(ACCESS_TOKEN_URI),
    authorizationUri: getEnv(AUTH_URI),
    redirectUri: getEnv(ORIGIN),
    scopes: ['openid'],
    logoutUri: getEnv(LOGOUT_URI)
}

export const dataFromAuth = (data) => ({
    user: data ? jwtDecode(data.id_token) : undefined,
    roles: data ? jwtDecode(data.access_token).realm_access.roles : undefined
})

export const authStorage = {
    get: () => JSON.parse(localStorage.getItem('authn')),
    set: (authn) => localStorage.setItem('authn', JSON.stringify(authn)),
    clear: () => localStorage.clear('authn')
}

export const userStorage = {
    get: () => JSON.parse(localStorage.getItem('userInfo')),
    set: (userInfo) => localStorage.setItem('userInfo', JSON.stringify(userInfo)),
    clear: () => localStorage.clear('userInfo')
}
